import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/website/website/src/templates/mdx-page.tsx";
import { SEO } from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Help" mdxType="SEO" />
    <h1>{`Help`}</h1>
    <h2>{`FAQ`}</h2>
    <h3>{`How do I remove a dish or assigned user from a meal?`}</h3>
    <p>{`Hold down on the dish or assigned user until a menu popups. Now select `}<strong parentName="p">{`Remove`}</strong>{`.`}</p>
    <h2>{`Contact`}</h2>
    <p>{`You could not find what you were looking for? Please send your support request to
`}<a parentName="p" {...{
        "href": "mailto:gabriel.cofu.app@gmail.com"
      }}>{`gabriel.cofu.app@gmail.com`}</a>{`.`}</p>
    <p>{`Last updated: Dec 6, 2020`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      